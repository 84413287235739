import React, { useState, useEffect } from 'react';
import './SearchFilter.css';

const SearchFilter = ({ onSearch, initialParams }) => {
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
  const [searchParams, setSearchParams] = useState(initialParams || {
    checkv_quality: [],
    baltimore_group: [],
    molGC_min: 0,
    molGC_max: 100,
    length_min: 0,
    length_max: 1000000,
    gene_count_min: 0,
    gene_count_max: 900,
    id: ''
  });

  useEffect(() => {
    setSearchParams(initialParams || {
      checkv_quality: [],
      baltimore_group: [],
      molGC_min: 0,
      molGC_max: 100,
      length_min: 0,
      length_max: 1000000,
      gene_count_min: 0,
      gene_count_max: 900,
      id: ''
    });
  }, [initialParams]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchParams((prevParams) => ({
      ...prevParams,
      [name]: value
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    setSearchParams((prevParams) => {
      const values = Array.isArray(prevParams[name]) ? [...prevParams[name]] : [];
      if (checked) {
        if (!values.includes(value)) {
          values.push(value);
        }
      } else {
        const index = values.indexOf(value);
        if (index > -1) {
          values.splice(index, 1);
        }
      }
      return {
        ...prevParams,
        [name]: values
      };
    });
  };

  const handleRangeChange = (e) => {
    const { name, value } = e.target;
    const minName = name.includes('_min') ? name : name.replace('_max', '_min');
    const maxName = name.includes('_max') ? name : name.replace('_min', '_max');

    const minValue = name.includes('_min') ? value : searchParams[minName];
    const maxValue = name.includes('_max') ? value : searchParams[maxName];

    if (Number(minValue) <= Number(maxValue)) {
      setSearchParams((prevParams) => ({
        ...prevParams,
        [name]: value
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = {
      ...searchParams,
      checkv_quality: Array.isArray(searchParams.checkv_quality) ? searchParams.checkv_quality.join(",") : searchParams.checkv_quality,
      baltimore_group: Array.isArray(searchParams.baltimore_group) ? searchParams.baltimore_group.join(",") : searchParams.baltimore_group,
    };
    onSearch(params);
    setShowAdvancedFilter(false);
  };

  return (
    <div className="search-filter-container">
      <button
        className={`accordion-button ${showAdvancedFilter ? '' : 'collapsed'}`}
        onClick={() => setShowAdvancedFilter(!showAdvancedFilter)}
      >
        Advanced Filter
      </button>
      {showAdvancedFilter && (
        <div className="accordion-content open">
          <form onSubmit={handleSubmit}>
            <div className="field-group">
              <label>CheckV Quality</label>
              <div className="checkbox-group">
                {['High-quality', 'Complete', 'Medium-quality', 'Low-quality', 'Not-determined'].map((quality) => (
                  <div key={quality}>
                    <label>
                      <input
                        type="checkbox"
                        name="checkv_quality"
                        value={quality}
                        checked={Array.isArray(searchParams.checkv_quality) && searchParams.checkv_quality.includes(quality)}
                        onChange={handleCheckboxChange}
                      />
                      {quality}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <hr />
            <div className="field-group">
              <label>Baltimore Group</label>
              <div className="checkbox-group">
                {['Group I', 'Group II', 'Group III', 'Group IV', 'Unclassified'].map((group) => (
                  <div key={group}>
                    <label>
                      <input
                        type="checkbox"
                        name="baltimore_group"
                        value={group}
                        checked={Array.isArray(searchParams.baltimore_group) && searchParams.baltimore_group.includes(group)}
                        onChange={handleCheckboxChange}
                      />
                      {group}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <hr />
            <div className="field-group">
              <label>molGC (%)</label>
              <div>
                <label className="min-max-label">Min</label>
                <input
                  type="number"
                  name="molGC_min"
                  value={searchParams.molGC_min}
                  min={0}
                  max={100}
                  onChange={handleRangeChange}
                  placeholder="Min"
                />
                <label className="min-max-label">Max</label>
                <input
                  type="number"
                  name="molGC_max"
                  value={searchParams.molGC_max}
                  min={0}
                  max={100}
                  onChange={handleRangeChange}
                  placeholder="Max"
                />
              </div>
            </div>
            <hr />
            <div className="field-group">
              <label>Genome Length (bp)</label>
              <div>
                <label className="min-max-label">Min</label>
                <input
                  type="number"
                  name="length_min"
                  value={searchParams.length_min}
                  min={0}
                  max={1000000}
                  onChange={handleRangeChange}
                  placeholder="Min"
                />
                <label className="min-max-label">Max</label>
                <input
                  type="number"
                  name="length_max"
                  value={searchParams.length_max}
                  min={0}
                  max={1000000}
                  onChange={handleRangeChange}
                  placeholder="Max"
                />
              </div>
            </div>
            <hr />
            <div className="field-group">
              <label>Gene Count</label>
              <div>
                <label className="min-max-label">Min</label>
                <input
                  type="number"
                  name="gene_count_min"
                  value={searchParams.gene_count_min}
                  min={0}
                  max={900}
                  onChange={handleRangeChange}
                  placeholder="Min"
                />
                <label className="min-max-label">Max</label>
                <input
                  type="number"
                  name="gene_count_max"
                  value={searchParams.gene_count_max}
                  min={0}
                  max={900}
                  onChange={handleRangeChange}
                  placeholder="Max"
                />
              </div>
            </div>
            <hr />
            <div className="field-group">
              <label>Filter by ID</label>
              <input
                type="text"
                name="id"
                value={searchParams.id}
                onChange={handleInputChange}
                placeholder="Enter Phage ID"
              />
            </div>
            <button type="submit">Filter</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default SearchFilter;