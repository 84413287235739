import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Header from './Header'; // Import Header component
import SearchFilter from './SearchFilter'; // Import SearchFilter component
import PhageCharts from './PhageCharts'; // Import PhageCharts component
import '../App.css'; // Ensure you import the CSS file containing loader styles

// Import Material-UI components
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
} from '@mui/material';
import { API_PHAGE_STATISTICS_URL, API_SEARCH_PHAGES_URL } from '../constants';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'index', numeric: false, disablePadding: false, label: '#' }, // New column for index
  { id: 'id', numeric: false, disablePadding: false, label: 'ID' },
  { id: 'checkv_quality', numeric: false, disablePadding: false, label: 'CheckV Quality' },
  { id: 'baltimore_group', numeric: false, disablePadding: false, label: 'Baltimore Group' },
  { id: 'length', numeric: true, disablePadding: false, label: 'Genome Length' },
  { id: 'molGC', numeric: true, disablePadding: false, label: 'molGC (%)' },
  { id: 'gene_count', numeric: true, disablePadding: false, label: 'Gene Count' },
  { id: 'definition', numeric: false, disablePadding: false, label: 'Description' },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ width: headCell.id === 'baltimore_group' ? '10%' : 'auto' }} // Adjust column width
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={{ visuallyHidden: false }}>
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function PhageSearchResults() {
  const { field, value } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statistics, setStatistics] = useState(null);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalResults, setTotalResults] = useState(0);
  const [searchParams, setSearchParams] = useState(() => {
    const searchParams = new URLSearchParams(location.search);
    return {
      checkv_quality: searchParams.getAll('checkv_quality'),
      baltimore_group: searchParams.getAll('baltimore_group'),
      molGC_min: parseFloat(searchParams.get('molGC_min')) || 0,
      molGC_max: parseFloat(searchParams.get('molGC_max')) || 100,
      length_min: parseInt(searchParams.get('length_min')) || 0,
      length_max: parseInt(searchParams.get('length_max')) || 1000000,
      gene_count_min: parseInt(searchParams.get('gene_count_min')) || 0,
      gene_count_max: parseInt(searchParams.get('gene_count_max')) || 900,
      id: searchParams.get('id') || ''
    };
  });

  const fetchResults = async (params, page = 1) => {
    try {
      const response = await axios.get(`${API_SEARCH_PHAGES_URL}`, { params: { ...params, page, limit: rowsPerPage } });
      setResults(response.data.results);
      setTotalResults(response.data.total);

      if (params.checkv_quality && params.checkv_quality.length > 0) {
        const statsResponse = await axios.get(`${API_PHAGE_STATISTICS_URL}`);
        setStatistics(statsResponse.data);
      }
    } catch (error) {
      setError(`Error fetching results: ${error.message}`);
      console.error('Error fetching results:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const params = { ...searchParams };
    if (field && value) {
      params[field] = value;
    }
    fetchResults(params, page + 1);
  }, [field, value, searchParams, page]);

  const handleSearch = (params) => {
    setLoading(true);
    setError(null);
    setResults([]);
    setPage(0);
    setSearchParams(params);
    const query = new URLSearchParams();
    Object.keys(params).forEach(key => {
      if (Array.isArray(params[key])) {
        params[key].forEach(value => {
          query.append(key, value);
        });
      } else {
        query.append(key, params[key]);
      }
    });
    navigate(`/filter?${query.toString()}`, { replace: true });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const sortedResults = stableSort(results, getComparator(order, orderBy));
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - results.length) : 0;

  if (loading) {
    return (
      <div className="container-fluid">
        <main role="main" className="col-md-12 ml-sm-auto col-lg-12 px-4">
          <Header />
          <div className="loader"></div>
        </main>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container-fluid">
        <main role="main" className="col-md-12 ml-sm-auto col-lg-12 px-4">
          <Header />
          <div>{error}</div>
        </main>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <main role="main" className="col-md-12 ml-sm-auto col-lg-12 px-4">
        <Header />
        <div className="row">
          <div className="col-md-9">
            <SearchFilter onSearch={handleSearch} initialParams={searchParams} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {field === 'checkv_quality' && statistics && (
              <PhageCharts statistics={statistics} />
            )}
            <div className="body-font">
              <p className="search-results-info">
                <strong>Found {totalResults} results matching the criteria.</strong>
              </p>
              <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 750 }}
                      aria-labelledby="tableTitle"
                      size='medium'
                    >
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={results.length}
                      />
                      <TableBody>
                        {sortedResults.map((phage, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              hover
                              tabIndex={-1}
                              key={phage.id || phage._id}
                            >
                              <TableCell component="th" id={labelId} scope="row">
                                {(page * rowsPerPage) + index + 1}
                              </TableCell>
                              <TableCell>
                                <Link to={`/phage/${phage.id || phage._id}`}>
                                  {phage.id || phage._id}
                                </Link>
                              </TableCell>
                              <TableCell>{phage.checkv_quality}</TableCell>
                              <TableCell>{phage.baltimore_group}</TableCell>
                              <TableCell>{phage.length}</TableCell>
                              <TableCell>{phage.molGC}</TableCell>
                              <TableCell>{phage.gene_count}</TableCell>
                              <TableCell>{phage.definition}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[]} // Disable the rows per page option
                    component="div"
                    count={totalResults}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </Paper>
              </Box>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}